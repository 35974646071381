import Preact from "preact";
import { useNavState } from "./Context.tsx";
import ProductNav from "./ProductNav.tsx";
import PageNav from "./PageNav.tsx";

export default function MainNav(props) {
  const { productState, navState, toggleNav, toggleProduct } = useNavState();

  return (
    <nav class={`nav-primary ${navState ? "open" : ""}`}>
      <ProductNav open={productState} onClick={toggleProduct} />
      <PageNav open={navState} onClick={toggleNav} />
    </nav>
  );
}
